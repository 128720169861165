<template>
  <div id="page-user-list">
      <form method="POST" enctype="multipart/form-data"  @submit.prevent="submitForm">
       <div class="vx-row mb-6">

        <div class="vx-col w-full md:w-2/3">
          <div class="vx-card p-6 mb-6">
            <HomeStayForm></HomeStayForm>
          
          </div>
          <div class="vx-card p-6 mb-6">
            <SeoInfo></SeoInfo>
          </div>
        </div>
        <div class="vx-col w-full md:w-1/3">
          <div class="vx-card p-6 mb-6">
              <AdditionInfo></AdditionInfo>
            </div>
            <div class="vx-card p-6 mb-6">
              <EditHomeStayImage></EditHomeStayImage>
            </div>
        </div>
       </div>

        <div class="vx-card p-4 mb-4">
              <LocationInfo></LocationInfo>
        </div>

            <!-- Save & Reset Button -->
        <div class="vx-card p-4 mb-4">
            <SettingInfo></SettingInfo>
 
            <div class="flex flex-wrap items-center justify-end">
              <vs-button button="submit" class="ml-auto" >Save Changes</vs-button>
            </div>
        </div>
      </form> 
	</div>
</template>

<script>
import vSelect from 'vue-select'
import { mapGetters, mapActions } from 'vuex'
import {storeImage} from '../../../services/image';


import HomeStayForm from './common/HomeStayForm';
import AdditionInfo from './common/AdditionInfo';
import SettingInfo from './common/SettingInfo';
import LocationInfo from './common/LocationInfo';
import SeoInfo from './common/SeoInfo';
import EditHomeStayImage from './common/EditHomestayImage';
import {HOMESTAY} from '../../../constant/entity-identifier';
import loaderMixin from '../../../mixins/loaderMixin';

export default {
  components: {
    vSelect,
    HomeStayForm,
    AdditionInfo,
    LocationInfo,
    SeoInfo,
    SettingInfo,
    EditHomeStayImage
  },
  mixins: [loaderMixin],
  computed: {
    ...mapGetters({
        form: 'homestay/getFormObj'
    }),
    getHomestayId() {
        return this.$route.params.homestayId;
    },
  },
  created() {
    this.clearForm();
    this.clearValidationErrors();
     this.openLoading();
     this.fetchAndSetHomeStayById(this.getHomestayId).then(() => {
       this.closeLoading();
     }).catch((r) => this.closeLoading());
  },
  methods: {
    ...mapActions({
      updateHomeStayAction: 'homestay/updateHomeStayAction',
      fetchAndSetHomeStayById: 'homestay/fetchAndSetHomeStayById',
      clearForm: 'homestay/clearForm',
      clearValidationErrors: 'validation/clearValidationErrors',
    }),
    submitForm() {
     this.openLoading();
      this.updateHomeStayAction({
        homestayId: this.getHomestayId,
        data: this.form
      }).then(response => {  
        if(response.status === 202) {
       this.closeLoading();
        this.$vs.notify({ 
            text: 'Homestay updated successfully.',
            title: 'Homestay Updated',
            color: 'success',
            position: 'top-right'
        });
        this.$router.push('/homestays');}
      }).catch(() => this.closeLoading());
    },
  },
}
</script>


<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
</style>
